<template>
  <div class="charts">
    <div class="charts__switch">
      <div class="charts__switch__text" :class="{ 'charts__switch__text--active': !isDonutVisible }">
        Gráfica de Barras
      </div>
      <div class="charts__switch__b-switch">
        <BSwitch v-model="isDonutVisible" :rounded="false" />
      </div>
      <div class="charts__switch__text" :class="{ 'charts__switch__text--active': isDonutVisible }">
        Gráfica de Pie
      </div>
    </div>
    <div class="charts__apex-chart">
      <div v-if="!isDonutVisible" class="charts__apex-chart__columns">
        <div class="charts__apex-chart__columns__text">
          <div>
            {{ totalValue }}
          </div>
          <div>{{ typeText }}</div>
        </div>
        <VueApexCharts type="bar" height="170" :options="columnsChartOptions" :series="columnsChartSeries" />
      </div>
      <div v-else class="charts__apex-chart__donut">
        <div class="charts__apex-chart__donut__text">
          <div>{{ totalValue }}</div>
          <div>{{ typeText }}</div>
        </div>
        <VueApexCharts type="donut" :options="donutChartOptions" :series="donutChartSeries" />
      </div>
    </div>
    <div class="charts__periods">
      <div class="charts__periods__text">{{ typeText }} por minutos del partido:</div>
      <div v-for="(period, index) of periods" :key="index" class="charts__periods__period">
        <div class="charts__periods__period__color" :style="{ 'background-color': period.color }" />
        <div class="charts__periods__period__text">{{ period.text }}</div>
        <div class="charts__periods__period__colon">:</div>
        <div class="charts__periods__period__percent">{{ period.value }}%</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'ChartsDonutAndColumns',
  components: {
    BSwitch: async () => {
      const { BSwitch } = await import('buefy/dist/esm/switch');
      return BSwitch;
    },
    VueApexCharts: () => import('vue-apexcharts'),
  },
  props: {
    type: {
      type: String,
      default: 'corners', // 'corners' or 'goals'
    },
    isAwayTeam: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDonutVisible: false,
      periods: [
        {
          color: '#f1c42e',
          text: '0-15',
          section: 1,
          value: 0,
        },
        {
          color: '#ff985b',
          text: '16-30',
          section: 2,
          value: 0,
        },
        {
          color: '#d92a3f',
          text: '31-45',
          section: 3,
          value: 0,
        },
        {
          color: '#5993f8',
          text: '46-60',
          section: 4,
          value: 0,
        },
        {
          color: '#7758c9',
          text: '61-75',
          section: 5,
          value: 0,
        },
        {
          color: '#85cb18',
          text: '76-90',
          section: 6,
          value: 0,
        },
      ],
    };
  },
  computed: {
    ...mapState('betsGeneral', ['teamStatPlayIndexes']),
    ...mapGetters('betsGeneral', ['cornersOrGoalsByPlayingAs']),
    typeText() {
      return this.type === 'corners' ? 'Tiros de esquina' : 'Goles';
    },
    sections() {
      return this.periods.map(period => period.section);
    },
    colors() {
      return this.periods.map(period => period.color);
    },
    categories() {
      return this.periods.map(period => period.text);
    },
    series() {
      const seriesMap = this.periods.reduce((accumulator, period) => {
        accumulator.set(period.section, {
          section: period.section,
          value: 0,
        });
        return accumulator;
      }, new Map());
      const stats = this.cornersOrGoalsByPlayingAs(this.isAwayTeam ? 'away' : 'home', this.type);
      stats.forEach(stat => {
        let section = seriesMap.get(stat[this.teamStatPlayIndexes.section]);
        if (section) {
          section.value += stat[this.teamStatPlayIndexes.total];
        }
      });
      const preliminarySeries = Array.from(seriesMap.values());
      preliminarySeries.sort((a, b) => {
        return a.section - b.section;
      });
      return preliminarySeries.map(section => section.value);
    },
    donutChartSeries() {
      return [...this.series];
    },
    columnsChartSeries() {
      return [
        {
          name: this.type === 'corners' ? 'T. de esquina' : 'Goles',
          data: this.series,
        },
      ];
    },
    donutChartOptions() {
      return {
        chart: {
          type: 'donut',
        },
        colors: this.colors,
        legend: {
          show: false,
        },
        labels: this.categories,
      };
    },
    columnsChartOptions() {
      return {
        chart: {
          height: '170px',
          type: 'bar',
          toolbar: {
            show: false,
          },
        },
        colors: this.colors,
        plotOptions: {
          bar: {
            columnWidth: '50%',
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: this.categories,
          labels: {
            style: {
              fontSize: '12px',
            },
          },
        },
      };
    },
    totalValue() {
      return this.series.reduce((accumulator, value) => {
        return accumulator + value;
      }, 0);
    },
  },
  watch: {
    totalValue: {
      immediate: true,
      handler(newValue) {
        if (newValue !== 0) {
          this.series.forEach((sectionValue, index) => {
            this.periods[index].value = ((sectionValue / newValue) * 100).toFixed(1);
          });
        } else {
          this.periods.forEach(period => {
            period.value = 0;
          });
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.charts {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Roboto-Regular, sans-serif;

  &__switch {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 1px dashed #94a8ba;

    & > div {
      align-self: center;

      &:first-child {
        justify-self: flex-end;
        padding-right: 0.75rem;
      }

      &:last-child {
        justify-self: flex-start;
        padding-left: 0.75rem;
      }
    }

    &__text {
      font-family: Roboto-Regular, sans-serif;
      font-size: 0.8rem;

      &--active {
        font-weight: bold;
      }
    }

    &__b-switch {
      padding-top: 0.3rem;
    }
  }

  &__apex-chart {
    margin-top: 0.2rem;
    color: #43474a;

    &__donut {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &__text {
        background-color: rgba(0, 0, 0, 0);
        z-index: 1;
        position: absolute;
        color: #4d4f52;
        font-weight: 600;

        & > div {
          &:first-child {
            font-size: 1.68rem;
            line-height: 0.62;
            margin-bottom: 0.5rem;
          }

          &:last-child {
            font-size: 0.76rem;
            line-height: 1.38;
          }
        }
      }
    }

    &__columns {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__text {
        margin-top: 0.95rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        line-height: 1;

        & > div {
          &:first-child {
            font-size: 1.8rem;
          }

          &:last-child {
            font-size: 0.8rem;
            margin-left: 0.2rem;
          }
        }
      }
    }
  }

  &__periods {
    margin-top: 0.4rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 0.625rem;
    row-gap: 0.625rem;
    width: 100%;

    &__text {
      grid-column-start: 1;
      grid-column-end: 4;
      font-size: 0.8125rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      text-align: center;
      color: #4d4f52;
    }

    &__period {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.11rem 0.2rem 0.1rem;
      border-radius: 3px;
      border: solid 1px #dee4ea;
      font-family: Roboto-Regular, sans-serif;
      font-size: 0.75rem;
      line-height: 1;

      &__color {
        height: 0.75rem;
        width: 0.75rem;
        border-radius: 4px;
      }

      &__text {
        font-weight: 600;
        white-space: nowrap;
      }

      &__colon {
        font-weight: 300;
      }

      &__percent {
        font-weight: 800;
      }
    }
  }
}
</style>

<style lang="scss">
.charts > .charts__switch {
  & .switch input[type='checkbox'] + .check {
    background: #9bd73a;
    height: 1.35em;

    &:before {
      height: 0.85em;
    }
  }

  & .switch input[type='checkbox']:checked + .check {
    background: #4496f4;
  }

  label.switch {
    margin-right: 0;
  }
}
</style>
